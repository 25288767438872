import { required, alpha } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import {
  validateCountryName,
  validateCountryCode,
  getCountryContinents,
  getCountryRegions,
  updateCountryData,
  addCountryData,
  getCountryDataPagination
} from '@/app/services/api';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'Country',
  props: {
    showModalState: Boolean,
    formState: String
  },
  components: {
    ModelSelect
  },
  watch: {
    currentPage: function() {
      this.getFilteredCountry();
    },
    search: function() {
      this.currentPage = 1;
      if (this.search && this.search.length >= 3) {
        this.getFilteredCountry();
      }
      if (this.search.length === 0) {
        this.getFilteredCountry();
      }
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFilteredCountry();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      unsubscribe: null,
      search: '',
      data: [],
      countryId: '',
      countryForm: {
        code: '',
        country: '',
        region: '',
        continent: ''
      },
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      countriesData: [],
      showModal: false,
      editMode: false,
      editCountry: false,
      countryNameError: '',
      countryCodeError: '',
      update: false,
      responseMsg: '',
      showAlert: false,
      isSuccess: false,
      isFailed: false,
      fields: [
        {
          key: 'code',
          sortable: true
        },
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'region',
          sortable: false
        },
        {
          key: 'continent',
          sortable: false
        }
      ],
      constinentArr: [],
      regionsArr: []
    };
  },
  validations: {
    countryForm: {
      code: {
        required,
        alpha
      },
      country: {
        required,
        alpha
      },
      region: {
        required
      },
      continent: {
        required
      }
    }
  },
  mounted() {
    this.getFilteredCountry();
    this.getCountryContinents();
    this.getCountryRegions();
  },
  methods: {
    validateCountryName: function() {
      const token = localStorage.getItem('token');
      this.countryNameError = '';
      const country = this.countryForm.country;
      validateCountryName(country, token)
        .then(resp => {
          if (resp.status == 200) {
            this.countryNameError = resp.data.message;
          }
        })
        .catch(err => {
          this.err = err;
        });
    },

    validateCountryCode: function() {
      const token = localStorage.getItem('token');
      this.countryCodeError = '';
      const code = this.countryForm.code;
      validateCountryCode(code, token)
        .then(resp => {
          if (resp.status == 200) {
            this.countryCodeError = resp.data.message;
          }
        })
        .catch(err => {
          this.err = err;
        });
    },

    getCountryContinents: function() {
      getCountryContinents()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            const continentsArr = response.data.data;
            const getModifyArr = continentsArr.map(arrObj => {
              return {
                value: arrObj,
                text: arrObj
              };
            });
            this.constinentArr = this.constinentArr.concat(getModifyArr);
          }
        })
        .then(e => {
          this.err = e;
        });
    },
    getCountryRegions: function() {
      getCountryRegions().then(response => {
        if (response && response.status === 200 && response.data) {
          const regionarry = response.data.data;
          const modifyarry = regionarry.map(type => {
            return {
              value: type.lookup_code,
              text: type.meaning
            };
          });
          this.regionsArr = this.regionsArr.concat(modifyarry);
        }
      });
    },

    async getFilteredCountry() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        key: this.search
      }
      try {
        const response = await getCountryDataPagination(
          this.currentPage - 1,
          this.perPage,
          this.search
        );
        const results = response.data.data.page;
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
        const country = results.map(type => {
          return {
            value: type.country_id,
            text: type.country
          };
        });
        this.countriesData = country;
      } catch (err) {
        this.err = err;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    resetModal() {
      this.countryCodeError = '';
      this.countryNameError = '';
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.update = false;
      this.showAlert = false;
      this.countryForm = {
        code: '',
        country: '',
        region: '',
        continent: '',
        region_lookup_code: ''
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    rowSelected: function(item) {
      this.showModal = true;
      this.showAlert = false;
      this.update = true;
      this.countryId = item.country_id;
      this.countryForm = {
        code: item.code,
        country: item.country,
        region: item.region_lookup,
        continent: item.continent
      };
    },
    updateCountry: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('clicked', 'edit');
        this.update = false;
        return;
      } else {
        const requestBody = {
          continent: this.countryForm.continent,
          country_code: this.countryForm.code,
          country_name: this.countryForm.country,
          region_lookup_code: this.countryForm.region
        };
        if (this.update) {
          this.loader = true;
          updateCountryData(requestBody, this.countryId)
            .then(response => {
              this.loader = false;
              if (response && response.status === 200) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.countrySuccessMsg;
              } else {
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              }
            })
            .catch(err => {
              this.err = err;
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        } else {
          addCountryData(requestBody)
            .then(response => {
              if (response && response.status === 201) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.countryAddedMsg;
              } else {
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'add') {
          this.showAlert = false;
          this.showModal = true;
          this.editMode = true;
          this.update = false;
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'country/getFilteredCountryList',
            'country',
            () => (this.loader = false)
          );
        }
        if (actionName === 'save') {
          this.updateCountry();
        }
        if (actionName === 'upload' && this.showModal) {
          this.eventBus.$emit('commonUpload', { id: this.countryId });
        }

      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
